<template>
  <v-btn @click="loginAsProvider" v-if="create">
    <span>{{ "action.login-as-provider" | t }}</span>
    <!-- <v-icon>fa fa-sign-in</v-icon> -->
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  computed: {
    create() {
      if(!this.formConfig.dataAction) return false
      return this.formConfig.dataAction.loginAsProvider
    },
    frontendWebDomain() {
      return this.$store.getters['base/frontendWebDomain']
    },
    redirectUrl() {
      return '/linerp/provider/info'
    },
  },
  methods: {
    async loginAsProvider() {
      let result = null
      try {
        const provider_id = this.$route.params.target
        result = await this.$api.collection.memberApi.getOfficialMemberToken(provider_id)
        const loginUrl = `${this.frontendWebDomain}/linerp/login-via-token?token=${result.token}&provider=${provider_id}&path=${this.redirectUrl}`
        window.open(loginUrl)
      } catch (error) {
        console.warn(error)
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
